import React from 'react';

import Ballon from 'components/jayden/ballon';

export interface ChatCustomMessageProps {
  name: string;
  imageUrl?: string;
  content: string;

  buttonGroup?: React.ReactNode;
}

const ChatCustomMessage: React.FC<ChatCustomMessageProps> = (props) => {
  const { name, imageUrl, content, buttonGroup = null } = props;

  return (
    // ballon power
    <Ballon>
      {/* chat_con */}
      <span className="!text-left text-primary-200">
        {/* channel_name */}
        <div className="mb-2 inline-flex h-10 items-center">
          {imageUrl != null && <img className="size-10 object-cover" src={imageUrl} />}

          {/* channel_name_text */}
          <span className="ml-3 text-16 font-semibold text-black-title rtl:ml-0 rtl:mr-3">{name}</span>
        </div>
        {/* power_con */}
        <div className="whitespace-pre-line border-t border-gray-300 px-1 pt-3 text-left text-13 font-light text-black-body">
          {content}
        </div>

        {buttonGroup}
      </span>
    </Ballon>
  );
};

export default ChatCustomMessage;
