import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { User } from '@sendbird/chat';
import { UserMessage } from '@sendbird/chat/message';
import { isAxiosError } from 'axios';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { MAX_PARTICIPANTS_LIMIT } from '../../../constants/common';
import useAlert from '../../../hooks/useAlert';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { getIsMaster, getIsStaff } from '../../../libs/helper';
import { searchMessages } from '../../../libs/sendbird';
import chatRoomExpandedState from '../../../store/atoms/chatRoomExpandedState';
import messageSearchKeywordState from '../../../store/atoms/messageSearchKeywordState';

import ChatInviteButton from './ChatInviteButton';

import chatApi from 'apis/chat-api';
import { ChannelType, GroupChannelDto } from 'apis/types/chat.type';
import { ReactComponent as LeaveIcon } from 'assets/icons/ic-chat-out.svg';
import { ReactComponent as ReportIcon } from 'assets/icons/ic-chat-report.svg';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/ic-chevron-down.svg';
import { ReactComponent as ExpandIcon } from 'assets/icons/ic-expand.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/ic-search.svg';
import { ReactComponent as SettingIcon } from 'assets/icons/ic-setting.svg';
import Input from 'components/jayden/Input';
import Tooltip from 'components/jayden/Tooltip';
import classNames from 'components/styled/util';

import useGroupChannel from 'hooks/useGroupChannel';

import { getChannelIdByChannelUrl } from 'utils/common';

type Props = {
  channel: GroupChannelDto;
};

const ChatRoomHeader: React.FC<Props> = ({ channel }) => {
  const refPrevChannel = useRef<GroupChannelDto | null>(null);
  const qc = useQueryClient();

  const [keyword, setKeyword] = useState('');
  const isChatRoomExpanded = useRecoilValue(chatRoomExpandedState);
  const [searchedMessages, setSearchedMessages] = useState<UserMessage[]>([]);
  const [searchedMessageIndex, setSearchedMessageIndex] = useState<number>(0);
  const [isShowSearch] = useState<boolean>(false);
  const { currentUser } = useCurrentUser();
  const { t } = useTranslation();

  const { initMessagesBySearch } = useGroupChannel();

  if (!currentUser) {
    return null;
  }

  const { show, close } = useAlert();
  const navigate = useNavigate();

  const isMaster = getIsMaster({ channel, user: currentUser as User });
  const isStaff = getIsStaff({ channel, user: currentUser as User });

  const isGeneralUser = useMemo(() => {
    const index = channel.members.findIndex(
      (member) => channel.staffIds.indexOf(member.userId) === -1 && channel.masterIds.indexOf(member.userId) === -1
    );
    return index > 0 && !isStaff && !isMaster;
  }, [channel, currentUser, isStaff, isMaster]);

  const leaveChannelMutation = useMutation({
    mutationFn: chatApi.putGroupChannelChannelIdLeave,
    onSuccess: async () => {
      close();
      await qc.invalidateQueries(['channels']);
      navigate('/channel');
    },
    onError: (error) => {
      let content = error?.toString?.();

      if (isAxiosError(error)) {
        content = error?.response?.data?.message;
      }

      show({
        title: 'Error',
        content,
        buttons: [
          {
            title: t('common.button.confirm'),
            onClick: close
          }
        ]
      });

      console.error('채널 나가기 실패', error);
    }
  });

  const onLeave = () => {
    show({
      content: t('chat.alert.leaveChannel.contents'),
      buttons: [
        {
          title: t('common.button.cancel'),
          onClick() {
            close();
          }
        },
        {
          title: t('common.button.confirm'),
          async onClick() {
            const channelId = getChannelIdByChannelUrl(channel.channelUrl);
            leaveChannelMutation.mutate({ channelId });
          }
        }
      ]
    });
  };

  const onSearch = useRecoilCallback(
    ({ set }) =>
      async () => {
        if (!keyword) {
          show({
            content: t('chat.alert.noSearchWord.contents'),
            buttons: [
              {
                title: t('common.button.confirm'),
                onClick: close
              }
            ]
          });
          return;
        }

        try {
          console.log('onSearch', keyword.trim());
          const result = await searchMessages(channel.channelUrl, keyword.trim());

          if (result.length === 0) {
            show({
              content: t('chat.channelHeader.alert.noMessage'),
              buttons: [
                {
                  title: t('common.button.confirm'),
                  onClick: close
                }
              ]
            });
            return;
          }

          const index = result.length - 1;
          set(messageSearchKeywordState, keyword);
          setSearchedMessageIndex(index);
          setSearchedMessages(result as UserMessage[]);
          initMessagesBySearch(channel, result[index].messageId);
        } catch (e) {
          show({
            title: 'Error',
            content: e?.toString?.(),
            buttons: [
              {
                title: t('common.button.confirm'),
                onClick: close
              }
            ]
          });
        }
      },
    [channel, keyword]
  );

  const onSearchTop = useCallback(() => {
    let newIndex = searchedMessageIndex - 1;
    if (newIndex < 0) {
      newIndex = searchedMessages.length - 1;
    }

    setSearchedMessageIndex(newIndex);
    initMessagesBySearch(channel, searchedMessages[newIndex].messageId);
  }, [channel, searchedMessageIndex, searchedMessages]);

  const onSearchBottom = useCallback(() => {
    let newIndex = searchedMessageIndex + 1;
    const lastIndex = searchedMessages.length - 1;
    if (newIndex > lastIndex) {
      newIndex = 0;
    }

    setSearchedMessageIndex(newIndex);
    initMessagesBySearch(channel, searchedMessages[newIndex].messageId);
  }, [channel, searchedMessageIndex, searchedMessages]);

  const onKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key !== 'Enter') {
        return;
      }

      if (!keyword) {
        return;
      }

      onSearch();

      event.currentTarget.blur();
    },
    [keyword, onSearch]
  );

  const onReport = useCallback(() => {
    navigate(`/channel/${channel.channelUrl}/report/channel`);
  }, [channel]);

  const onSetting = useCallback(() => {
    if (isMaster) {
      navigate(`/channel/${channel.channelUrl}/setting/overview`);
    }
  }, [isMaster, channel]);

  const onChangeKeyword = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
  }, []);

  const initSearchKeyword = useRecoilCallback(
    ({ set }) =>
      () => {
        set(messageSearchKeywordState, '');
        setKeyword('');
      },
    []
  );

  const onCollapse = useRecoilCallback(
    ({ set }) =>
      () => {
        set(chatRoomExpandedState, false);
      },
    []
  );

  useEffect(() => {
    if (refPrevChannel.current?.channelUrl !== channel.channelUrl) {
      initSearchKeyword();
    }
  }, [channel]);

  return (
    <div className={classNames('flex h-[68px] items-center justify-between bg-black-title px-5 py-2.5')}>
      <div className="inline-flex items-center">
        <div className="channel_img shrink-0">
          {/* 채팅방 개설시 등록한 썸네일 */}
          <img className="size-12 rounded-2" src={channel.coverUrl} />
          {/* 음성채팅방의 경우 음성표시 썸네일에 노출 */}
          {/* <div className="img_over" /> */}
        </div>
        <div className="ml-3 rtl:ml-0 rtl:mr-3">
          {/* 채팅방 개설시 등록한 채널명 */}
          <div className="mb-1 line-clamp-1 h-[23px] break-all text-16 font-semibold text-white">{channel.name}</div>
          {/* 가입가능한 멤버 수와 가입되어있는 멤버의 수 */}
          <div className="text-13 text-gray-600">
            {t('chat.channelHeader.userCountInfo', {
              maxCount: channel.participantsLimit === MAX_PARTICIPANTS_LIMIT ? '∞' : channel.participantsLimit,
              joinCount: channel.memberCount
            })}
          </div>
        </div>
      </div>
      <div className="flex h-10 items-center gap-1.5">
        {/* 채팅창 상단 메뉴는 3가지로 검색은 기본 / 방장일경우 메뉴(나가기/설정) / 일반유저일경우(신고하기/나가기) [순서에 유의해주세요]*/}
        <div className="flex items-center gap-3">
          <div
            className={classNames('btn_area w-[188px] overflow-hidden transition-all', {
              '!w-0': !isShowSearch
            })}
          >
            <div className="flex gap-1">
              <Input
                type="text"
                placeholder={t('chat.channelHeader.search.placeholder')}
                className="border-transparent bg-slate-default focus:border-white"
                value={keyword}
                onChange={onChangeKeyword}
                onKeyUp={onKeyUp}
                suffix={
                  <button
                    className="absolute right-2 top-3 text-white opacity-50 transition-all hover:opacity-100 peer-focus:opacity-100"
                    onClick={onSearch}
                    disabled={!keyword}
                  >
                    <SearchIcon className="size-5" />
                  </button>
                }
              />

              <div className="flex flex-col">
                <button
                  type="button"
                  className="h-5 w-6 px-1 py-0.5 text-white opacity-50 hover:opacity-100"
                  onClick={onSearchTop}
                  disabled={searchedMessages.length === 0}
                >
                  <ChevronDownIcon className="size-4 rotate-180" />
                </button>
                <button
                  type="button"
                  className="h-5 w-6 px-1 py-0.5 text-white opacity-50 hover:opacity-100"
                  onClick={onSearchBottom}
                  disabled={searchedMessages.length === 0}
                >
                  <ChevronDownIcon className="size-4" />
                </button>
              </div>
            </div>
          </div>

          {/* <div className="relative">
            <button type="button" className="open_tip group" onClick={() => setIsShowSearch((prev) => !prev)}>
              {isShowSearch ? (
                <CloseIcon className="size-5 text-white opacity-50 group-hover:opacity-100" />
              ) : (
                <SearchIcon className="size-5 text-white opacity-50 group-hover:opacity-100" />
              )}
              <Tooltip position="bottom" className="">
                {isShowSearch ? (
                  <span className="whitespace-nowrap">{t('chat.header.sidebar.closeSearch')}</span>
                ) : (
                  <span>{t('chat.header.sidebar.search')}</span>
                )}
              </Tooltip>
            </button>
          </div>
          <div className="h-5 w-px bg-slate-default" /> */}
        </div>

        <div className="flex items-center">
          {/* S : 채팅방 검색 */}
          {/* 채팅창에서 ctrl+f (찾기)를 눌렀을 때도 채팅상단 검색에 포커스가 되도록 해주세요! */}
          {/* 검색대상은 채팅창 내 모든 텍스트입니다. (공지사항, 닉네임, 파일명 등등) */}
          {/* 검색은 최신 글에서부터 검색 후 검색창 옆 상하버튼으로 검색 결과로 이동합니다. (카톡방식) */}
          {/* E : 채팅방 검색 */}
          {/* 일반 유저일 경우 신고 아이콘 */}
          {(isGeneralUser || channel.customType === ChannelType.DM) && (
            <div className="relative">
              <button type="button" className="group flex size-8 items-center justify-center" onClick={onReport}>
                <ReportIcon className="text-white opacity-50 group-hover:opacity-100" />

                <Tooltip position="bottom">
                  <span>{t('chat.channelHeader.tooltip.report')}</span>
                </Tooltip>
              </button>
            </div>
          )}

          {channel.customType !== ChannelType.DM && (
            <>
              <ChatInviteButton />

              {isMaster && (
                <div className="relative">
                  <button type="button" className="group flex size-8 items-center justify-center" onClick={onSetting}>
                    <SettingIcon className="text-white opacity-50 group-hover:opacity-100" />
                    <Tooltip position="bottom">
                      <span>{t('chat.channelHeader.tooltip.setting')}</span>
                    </Tooltip>
                  </button>
                </div>
              )}
            </>
          )}

          {/* 나가기 했을 때 Alert(Are you sure leave the room?) / Button : Cancle,Confirm */}
          {!isMaster && (
            <div className="relative">
              <button type="button" className="group flex size-8 items-center justify-center" onClick={onLeave}>
                <LeaveIcon className="text-white opacity-50 group-hover:opacity-100" />
                <Tooltip position="bottom">
                  <span>{t('chat.channelHeader.tooltip.leave')}</span>
                </Tooltip>
              </button>
            </div>
          )}

          {isChatRoomExpanded && (
            <div className="" onClick={onCollapse}>
              <button type="button" className="group flex size-8 items-center justify-center">
                <ExpandIcon className="text-white opacity-50 group-hover:opacity-100" />

                <Tooltip position="bottom">
                  <span>{t('chat.channelHeader.tooltip.expand')}</span>
                </Tooltip>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatRoomHeader;
