import React, { useCallback, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { GifsResult, GiphyFetch } from '@giphy/js-fetch-api';
import { IGif } from '@giphy/js-types';
import { Grid } from '@giphy/react-components';

import useElementSize from '../../../hooks/useElementSize';
import useMessageInput from '../../../hooks/useMessageInput';
import useTextInputChange from '../../../hooks/useTextInputChange';
import { CustomMessageType } from '../../../types/common';

import { ReactComponent as SearchIcon } from 'assets/icons/ic-search.svg';
import Input from 'components/jayden/Input';

import { useCurrentChannel } from 'hooks/use-current-channel';

const ChatMessageEmoticonGifInput = () => {
  const { closeEmojiPicker, sendMessageToChannel } = useMessageInput();
  const [keyword, onChangeKeyword] = useTextInputChange('', 30);

  const refGf = useRef<GiphyFetch | null>(null);
  const [containerRef, { width: containerWidth }] = useElementSize();
  const { data: currentChannel } = useCurrentChannel();
  const { t } = useTranslation();

  const onSelectGif = async (gif: IGif) => {
    if (!currentChannel) return;

    closeEmojiPicker();
    await sendMessageToChannel(currentChannel, {
      message: gif.images.original.url,
      customType: CustomMessageType.GIF
    });
  };

  const fetchGifs = useCallback(
    async (offset: number): Promise<GifsResult> => {
      if (!refGf.current) {
        return {
          data: [],
          meta: {
            msg: '',
            response_id: '',
            status: 0
          },
          pagination: {
            count: 0,
            offset: 0,
            total_count: 0
          }
        };
      }

      if (!keyword) {
        const result = await refGf.current?.trending({
          offset,
          limit: 10
        });
        return result;
      }

      const result = await refGf.current?.search(keyword, {
        offset,
        limit: 10
      });
      return result;
    },
    [keyword]
  );

  useEffect(() => {
    if (!refGf.current) {
      refGf.current = new GiphyFetch('t8C4qqiT51Q8OG9K8bLoBN6LZtmGd2dz');
    }
  }, []);

  return (
    <div className="my-3 h-[calc(100%-83px)] overflow-y-scroll [&_.loader]:hidden" ref={containerRef}>
      <Input
        type="text"
        placeholder={t('chat.stickerPopup.search.placeholder')}
        className="peer"
        value={keyword}
        onChange={onChangeKeyword}
        suffix={
          <SearchIcon className="absolute right-3 top-1/2 inline-block size-6 -translate-y-1/2 cursor-pointer text-gray-500 transition-all peer-focus:text-black-body rtl:left-3 rtl:right-auto" />
        }
      />

      <div className="h-3" />

      <Grid
        width={containerWidth - 8}
        columns={3}
        fetchGifs={fetchGifs}
        key={keyword}
        hideAttribution={true}
        onGifClick={onSelectGif}
        noLink
        noResultsMessage={
          <div className="flex h-[299px] flex-col items-center justify-center gap-5 text-center">
            <img src="/img/com/img-error-page.webp" className="w-[200px]" alt="no result" />
            <div className="text-18 font-bold text-black-title">
              <Trans i18nKey="chat.stickerPopup.gif.empty.contents">{{ searchWord: keyword }}</Trans>
            </div>
          </div>
        }
      />

      {/* E : 검색결과 */}
    </div>
  );
};
export default ChatMessageEmoticonGifInput;
