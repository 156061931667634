import React, { Children, ReactNode, useMemo } from 'react';

import classNames, { Argument } from 'classnames';

import BackButton from '../buttons/BackButton';

type Props = {
  height?: number | string;
  width?: 'w480' | 'w680' | 'w600' | string;
  Buttons?: ReactNode;
  HeaderContent?: ReactNode;
  children: ReactNode;
  contentClassNames?: Argument;
};

const ModalContainer: React.FC<Props> = ({
  height = 640,
  width = 'w480',
  Buttons,
  HeaderContent,
  children,
  contentClassNames = {}
}) => {
  const buttonContainerClassNames = useMemo(() => {
    const buttons = Children.toArray(Buttons);

    return classNames({
      modal_btn_wrap: true,
      full: buttons.length === 1,
      line: buttons.length > 1,
      between: buttons.length > 1
    });
  }, [Buttons]);

  return (
    <>
      <div id="modal" style={{ zIndex: 20 }} />
      <div className={`modal_popup ${width}`} style={{ height }}>
        <BackButton className="close rtl:!left-4 rtl:!right-auto" />
        {/* S : Popup Content */}
        <div
          className={classNames(
            {
              modal_con: true
            },
            contentClassNames
          )}
        >
          {HeaderContent}
          {children}
        </div>
        {/* E : Popup Content */}
        {/* S : Popup Button */}
        {/* 필수입력값이 입력되지않으면 disable상태로 됩니다. */}
        {Buttons != null && <div className={buttonContainerClassNames}>{Buttons}</div>}

        {/* E : Popup Button */}
      </div>
    </>
  );
};

export default ModalContainer;
