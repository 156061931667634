import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Sender } from '@sendbird/chat/message';
import classNames from 'classnames';

import { CustomMessageType } from '../../../types/common';

import ProfileAvatar from 'components/profileAvatar/ProfileAvatar';

type Props = {
  user: Sender | null;
  customType: CustomMessageType;
  isMine?: boolean;
};

const ChatMessageProfileImage: React.FC<Props> = ({ user, isMine = false }) => {
  const navigate = useNavigate();

  const onProfile = useCallback(() => {
    if (document.location.href.indexOf('miniprofile') > -1) {
      navigate(-1);
      return;
    }
    if (!user) {
      return;
    }

    navigate(`miniprofile/${user.userId}`, {
      replace: false
    });
  }, [navigate]);

  return (
    <div
      className={classNames('absolute top-0', {
        'left-0 rtl:left-auto rtl:right-0': !isMine,
        'right-0 rtl:right-auto rtl:left-0': isMine
      })}
      onClick={onProfile}
    >
      <ProfileAvatar
        userInfo={{
          profile_image_url: user?.profileUrl
        }}
        size={40}
      />
    </div>
  );
};
export default ChatMessageProfileImage;
