import React, { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { User } from '@sendbird/chat';
import classNames from 'classnames';
import parse from 'html-react-parser';
import { useRecoilValue } from 'recoil';

import { textToLink } from '../../../../libs/helper';
import { UploadToS3Response } from '../../../../libs/s3';
import messageSearchKeywordState from '../../../../store/atoms/messageSearchKeywordState';
import { ChatMessage, CustomMessageType } from '../../../../types/common';

import { ReactComponent as ReplyIcon } from 'assets/icons/ic-reply-sm-bk.svg';
import Ballon from 'components/jayden/ballon';
import translateApi from 'libs/translateApi';
import messageTranslateKeysState from 'store/atoms/messageTranslateKeysState';

import useUserMe from 'hooks/use-user-me';

const getBrowserLanguage = () => {
  const language = navigator.language.split('-')[0];
  return language;
};

type Props = {
  id: number;
  text: string;
  parentMessage?: ChatMessage;
  mentionedUsers?: User[];
  isMe?: boolean;
};

const ChatTextMessage: React.FC<Props> = ({ id, text, parentMessage, mentionedUsers, isMe }) => {
  const navigate = useNavigate();
  const keyword = useRecoilValue(messageSearchKeywordState);
  const messageTranslateKeysStateValue = useRecoilValue(messageTranslateKeysState);
  const isTranslate = messageTranslateKeysStateValue.includes(id);

  const fileMessage = useMemo<UploadToS3Response>(() => {
    if (!parentMessage) {
      return null;
    }

    try {
      const parsed = JSON.parse(parentMessage.message);
      return parsed[0];
    } catch {
      return {};
    }
  }, [parentMessage?.message]);

  const imageMessage = useMemo<UploadToS3Response[]>(() => {
    if (!parentMessage) {
      return null;
    }

    try {
      const parsed = JSON.parse(parentMessage.message);
      return parsed;
    } catch {
      return {};
    }
  }, [parentMessage?.message]);

  const onClickMentionedUser = useCallback(
    (userId: string) => () => {
      navigate(`miniprofile/${userId}`, {
        replace: false
      });
    },
    []
  );

  const htmlMessage = useMemo(() => {
    let replaced = text;

    if (keyword) {
      replaced = replaced.replace(keyword, `<span class='keyworded notranslate'>${keyword}</span>`);
    }

    if (mentionedUsers) {
      for (const mentionedUser of mentionedUsers) {
        replaced = replaced.replace(
          `@${mentionedUser.nickname}`,
          `<span class='mentioned notranslate' userid='${mentionedUser.userId}'>@${mentionedUser.nickname}</span>`
        );
      }
    }

    return replaced;
  }, [text, mentionedUsers]);

  const myInfo = useUserMe();

  const { data: translateText = '', isLoading } = useQuery({
    queryKey: ['translate', htmlMessage],
    queryFn: () => translateApi.getMessages({ msg: htmlMessage, to: myInfo?.language_code || getBrowserLanguage() }),
    enabled: isTranslate && !!myInfo,
    cacheTime: Infinity,
    staleTime: Infinity,

    select: (data) => data?.data?.translations?.[0]?.translatedText
  });

  return (
    <Ballon isMe={isMe}>
      {!!parentMessage && (
        <>
          {/* 답글 원글 내용이 상단에 나옵니다. (글쓴이 / 내용 두 줄)  */}
          {/* 답글 원글 클릭 시 원글로 이동합니다.  */}
          <div className="text-12 font-medium leading-[18px] text-secondary-100">{parentMessage.sender.nickname}</div>
          {parentMessage.customType === CustomMessageType.TEXT && (
            <span className="mb-2 text-12 font-normal leading-[18px] text-gray-500">{parentMessage.message}</span>
          )}
          {parentMessage.customType === CustomMessageType.FILE && (
            <span className="mb-2 text-12 leading-[18px] text-gray-500">{fileMessage.orgFileName}</span>
          )}
          {parentMessage.customType === CustomMessageType.IMAGE && (
            <ul className="mb-2 mt-1 flex items-center gap-1">
              {imageMessage?.slice(0, 5).map((image) => (
                <li key={image.cloudFrontUrl} className="size-10 shrink-0 rounded-2">
                  <img className="size-full rounded-2 object-cover" src={image.cloudFrontUrl} />
                </li>
              ))}
            </ul>
          )}
          {parentMessage.customType === CustomMessageType.VIDEO && (
            <ul className="mb-2 mt-1 inline-flex items-center gap-1">
              {imageMessage?.slice(0.5).map((image) => (
                <li key={image.cloudFrontUrl} className="size-10 rounded-2">
                  {image.contentType.indexOf('image') > -1 && (
                    <img className="size-10 rounded-2" src={image?.cloudFrontUrl} />
                  )}
                  {image.contentType.indexOf('video') > -1 && (
                    <video width={40} height={40} controls={false} autoPlay={false} style={{ zIndex: 1 }}>
                      <source src={`${image.cloudFrontUrl}#t=0.1`} type={image.contentType} />
                    </video>
                  )}
                </li>
              ))}
            </ul>
          )}
        </>
      )}

      <span
        className={classNames('inline-flex w-full max-w-[420px] break-all text-13 text-black', {
          'border-t border-gray-200 pt-2 pl-2': !!parentMessage
          // 'justify-end': isMe
        })}
      >
        {parentMessage && <ReplyIcon />}

        <span
          className="whitespace-pre-wrap break-all"
          style={{ ...(isTranslate && !isLoading && { fontStyle: 'italic' }) }}
        >
          {parse(textToLink(isTranslate && !isLoading ? translateText : htmlMessage), {
            replace: (domNode: any) => {
              if (domNode.attribs?.class === 'mentioned') {
                return (
                  <span
                    onClick={onClickMentionedUser(domNode.attribs?.userid)}
                    className="mentioned"
                    style={{ wordBreak: 'break-all' }}
                  >
                    {domNode.children[0].data}
                  </span>
                );
              }

              return domNode;
            }
          })}
        </span>
      </span>
    </Ballon>
  );
};
export default ChatTextMessage;
