import axios, { AxiosError, type AxiosRequestConfig, type InternalAxiosRequestConfig } from 'axios';

import { i18nDayjsLanguageCode } from 'constants/date.type';

import { getAccessToken } from 'utils/common';

interface createAxiosInstanceProps extends AxiosRequestConfig {
  withToken?: boolean;
  withLang?: boolean;
}

export const createAxiosInstance = ({ withToken = false, withLang = false, ...config }: createAxiosInstanceProps) => {
  const instance = axios.create({ ...config });

  instance.interceptors.request.use(requestInterceptor({ withToken, withLang }), requestErrorInterceptor);
  instance.interceptors.response.use(responseInterceptor, responseErrorInterceptor);
  return instance;
};

type requestInterceptorProps = Pick<createAxiosInstanceProps, 'withToken' | 'withLang'>;

const requestInterceptor =
  ({ withToken, withLang }: requestInterceptorProps) =>
  async (config: InternalAxiosRequestConfig) => {
    const languageCode = window.localStorage.getItem('i18nextLng');

    if (withToken) {
      const accessToken = getAccessToken();

      if (accessToken) {
        config.headers['Authorization'] = accessToken;
      }
    }

    if (withLang && languageCode) {
      config.headers['Language-Code'] = i18nDayjsLanguageCode[languageCode as 'kr' | 'jp' | 'en'];
      config.headers['Accept-Language'] = i18nDayjsLanguageCode[languageCode as 'kr' | 'jp' | 'en'];
    }

    if (languageCode && withLang && config.method === 'get') {
      config.params = { ...config.params, language_code: languageCode };
    }

    return config;
  };

const requestErrorInterceptor = (error: AxiosError) => Promise.reject(error);

const responseInterceptor = (response: any) => response;

const responseErrorInterceptor = async (error: AxiosError) => {
  if (error.code === 'ERR_NETWORK_ERROR' && navigator.onLine) {
    // handle network error
    // window.location.href = '/system-check';
  }

  if (error.response?.status === 401) {
    // handle 401 error
  }

  return Promise.reject(error);
};
