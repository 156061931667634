const i18nDayjsLanguageCode = {
  kr: 'ko',
  jp: 'ja',
  en: 'en',
  ar: 'ar'
};

const DateFormatType = {
  SHORT: 'short',
  LONG: 'long',
  NORMAL: 'normal'
};

export { i18nDayjsLanguageCode, DateFormatType };
