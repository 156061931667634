import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';

import { CHAT_MESSAGE_MAX_LENGTH } from '../../../constants/common';
import useGroupChannel from '../../../hooks/useGroupChannel';
import useMessage from '../../../hooks/useMessage';
import { chatMessageStateByMessageId } from '../../../store/atoms/chatMessagesState';
import messageMoreState from '../../../store/atoms/messageMoreState';
import { CustomMessageType } from '../../../types/common';

import ChatMessageDate from './ChatMessageDate';
import ChatMessageMoreButton from './ChatMessageMoreButton';
import ChatMessageProfileImage from './ChatMessageProfileImage';
import LikedUserList from './LikedUserList';
import ChatCoinTransactionMessage from './message/ChatCoinTransactionMessage';
import ChatCustomMessage from './message/ChatCustomMessage';
import ChatFileMessage from './message/ChatFileMessage';
import ChatGifMessage from './message/ChatGifMessage';
import ChatImageMessage from './message/ChatImageMessage';
import ChatJoinLeaveMessage from './message/ChatJoinLeaveMessage';
import ChatNotificationMessage from './message/ChatNotificationMessage';
import ChatPollMessage from './message/ChatPollMessage';
import ChatTextMessage from './message/ChatTextMessage';
import ChatTransferMasterMessage from './message/ChatTransferMasterMessage';
import ChatVideoMessage from './message/ChatVideoMessage';
import ChatWelcomeMessage from './message/ChatWelcomeMessage';
import ChatClubTransferMessage, {
  PlatformTransferMessageDataInterface
} from './message/PlatformTransferMessage/ClubTransferMessage';

import { GroupChannelDto } from 'apis/types/chat.type';
import classNames from 'components/styled/util';

type Props = {
  prevMessageId: number;
  messageId: number;
  channel: GroupChannelDto;
  refChattingArea: React.RefObject<HTMLDivElement>;
};

const ChatMessageListItem: React.FC<Props> = (props: Props) => {
  const { messageId, prevMessageId, channel, refChattingArea } = props;

  const navigate = useNavigate();
  const _prevMessage = useRecoilValue(chatMessageStateByMessageId(prevMessageId));
  const message = useRecoilValue(chatMessageStateByMessageId(messageId));

  const { isDmChannel } = useGroupChannel();
  const [messageMoreStateValue, _setMessageMoreStateValue] = useRecoilState(messageMoreState);

  const prevMessage = useMessage({
    message: _prevMessage
  });

  const currMessage = useMessage({
    message
  });

  const ballonConClassNames = 'group flex items-end rtl:items-start';

  const isProfileView = prevMessage.userId !== currMessage.userId;

  const balloonConDivClassName = useMemo(() => {
    const classes: string[] = [ballonConClassNames];

    if (message.parentMessageId) {
      classes.push('reply');
    }

    if (message.customType === CustomMessageType.FILE) {
      classes.push('file');
    }

    return classNames(
      classes,
      {
        'justify-end pr-11 rtl:pl-11 rtl:pr-0': currMessage.isMine,
        'pl-11 rtl:pr-11 rtl:pl-0': !currMessage.isMine
      },
      {
        'pt-1': !isProfileView,
        'pt-[19px] mt-4': isProfileView
      }
    );
  }, [message.parentMessageId, message.customType, isProfileView]);

  const defaultClassNames = 'mx-5 relative';
  const masterClassNames = 'text-12 text-primary-100';
  // const testClassNames = 'flex-row-reverse';

  const globalChatDivClassName = useMemo(() => {
    const classes: string[] = [defaultClassNames];
    if (currMessage.isMine) {
      // classes.push('justify-end pr-11');
    } else {
      // classes.push(testClassNames);
    }

    if (currMessage.isMaster) {
      classes.push(masterClassNames);
    }
    if (currMessage.isStaff) {
      classes.push('staff');
    }

    if (currMessage.customType === CustomMessageType.SYSTEM) {
      classes.push('notice');
    }

    return classNames(classes, {});
  }, [currMessage.customType, currMessage.isMine, currMessage.isMaster, currMessage.isStaff]);

  const onMouseOver = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const value = snapshot.getLoadable(messageMoreState).getValue();

        if (value.popup === false && value.messageId !== messageId) {
          // set(messageMoreState, {
          //   popup: false,
          //   messageId,
          // });
          return;
        }
      },
    [messageId]
  );

  const createdAt = new Date(message.createdAt);

  const onProfile = useCallback(
    (userId?: string) => () => {
      if (document.location.href.indexOf('miniprofile') > -1) {
        navigate(-1);
        return;
      }
      if (!userId) {
        return;
      }

      navigate(`miniprofile/${userId}`, {
        replace: false
      });
    },
    [navigate]
  );

  const isMe = currMessage.isMine;

  if (message.sender?.isBlockedByMe) {
    return null;
  }

  if (currMessage.customType === CustomMessageType.CREATED_CHANNEL) {
    if (isDmChannel === true) {
      return null;
    }
    return <ChatWelcomeMessage channel={channel} />;
  }

  if (
    message.customType === CustomMessageType.LEAVE ||
    message.customType === CustomMessageType.JOIN ||
    message.customType === CustomMessageType.INVITATION ||
    message.customType === CustomMessageType.KICK
  ) {
    return <ChatJoinLeaveMessage message={message} />;
  }

  if (message.customType === CustomMessageType.SYSTEM) {
    return null;
  }

  if (message?.message?.length >= CHAT_MESSAGE_MAX_LENGTH) {
    return null;
  }

  return (
    <>
      <div className={globalChatDivClassName} onMouseOver={onMouseOver}>
        <div className={balloonConDivClassName}>
          {isProfileView && currMessage.isMine === false && (
            <>
              <ChatMessageProfileImage user={currMessage.user} customType={currMessage.customType} />
              <span
                className={classNames('absolute left-11 top-0 text-12 font-medium rtl:right-11', {
                  'text-primary-100': currMessage.isMaster,
                  'text-black-title': !currMessage.isMaster
                })}
                onClick={onProfile(currMessage.userId)}
              >
                {currMessage.customType === CustomMessageType.SYSTEM ? 'Notification' : currMessage.user?.nickname}
              </span>
            </>
          )}
          {currMessage.isMine === true && <ChatMessageDate createdAt={createdAt} />}
          {currMessage.isMine === true && (
            <ChatMessageMoreButton
              isMine={true}
              message={message}
              isLikedByMe={currMessage.isLikedByMe}
              moreState={messageMoreStateValue}
              refChattingArea={refChattingArea}
            />
          )}

          {/* SYSTEM message */}
          {currMessage.customType === CustomMessageType.SYSTEM && (
            <ChatNotificationMessage isMe={isMe} text={message.message} />
          )}

          {/* TEXT message */}
          {currMessage.customType === CustomMessageType.TEXT && (
            <ChatTextMessage
              id={message.messageId}
              text={message.message}
              parentMessage={message.parentMessage}
              mentionedUsers={message.mentionedUsers}
              isMe={isMe}
            />
          )}

          {/* CLUB_TRANSFER Message */}
          {currMessage.customType === CustomMessageType.CLUB_TRANSFER && (
            <ChatClubTransferMessage
              message={message}
              transferData={JSON.parse(message.data!) as PlatformTransferMessageDataInterface}
            />
          )}

          {/* file message */}
          {currMessage.customType === CustomMessageType.IMAGE && (
            <ChatImageMessage message={message} parentMessage={message.parentMessage} />
          )}
          {currMessage.customType === CustomMessageType.FILE && (
            <ChatFileMessage message={message} parentMessage={message.parentMessage} />
          )}
          {currMessage.customType === CustomMessageType.GIF && (
            <ChatGifMessage isMe={isMe} url={message.message} parentMessage={message.parentMessage} />
          )}
          {currMessage.customType === CustomMessageType.VIDEO && (
            <ChatVideoMessage message={message} parentMessage={message.parentMessage} />
          )}
          {currMessage.customType === CustomMessageType.URL && (
            <ChatTextMessage
              id={message.messageId}
              text={message.message}
              parentMessage={message.parentMessage}
              mentionedUsers={message.mentionedUsers}
              isMe={isMe}
            />
          )}

          {/* {currMessage.customType === CustomMessageType.INVITATION && <ChatInvitationMessage message={message} />} */}

          {/* 마스터 이전 */}
          {currMessage.customType === CustomMessageType.MASTER_TRANSFER && (
            <ChatTransferMasterMessage message={message} />
          )}

          {currMessage.customType === CustomMessageType.DEFAULT && (
            <ChatCustomMessage
              content={message.message}
              name={currMessage?.data?.name ?? ''}
              imageUrl={currMessage?.data?.imageUrl}
            />
          )}

          {/* POLL */}
          {currMessage.customType === CustomMessageType.POLL && <ChatPollMessage isMe={isMe} vote={currMessage.data} />}

          {/* COIN_TRANSACTION */}
          {currMessage.customType === CustomMessageType.COIN_TRANSACTION && (
            <ChatCoinTransactionMessage isMe={isMe} senderName={''} data={currMessage.data} />
          )}

          {currMessage.isMine === false && <ChatMessageDate createdAt={createdAt} />}
          {currMessage.isMine === false && (
            <ChatMessageMoreButton
              isMine={false}
              message={message}
              isLikedByMe={currMessage.isLikedByMe}
              moreState={messageMoreStateValue}
              refChattingArea={refChattingArea}
            />
          )}

          {isProfileView && currMessage.isMine === true && (
            <>
              {/* chat_name */}
              <span
                className={classNames('absolute right-11 top-0 text-12 font-medium rtl:left-11 rtl:right-auto', {
                  'text-primary-100': currMessage.isMaster,
                  'text-black-title': !currMessage.isMaster
                })}
                onClick={onProfile(currMessage.userId)}
              >
                {currMessage.user?.nickname}
              </span>
              <ChatMessageProfileImage isMine user={currMessage.user} customType={currMessage.customType} />
            </>
          )}
        </div>
      </div>
      {message.reactionCount > 0 && (
        <LikedUserList
          isMine={currMessage.isMine}
          countLiked={message.reactionCount}
          messageId={message.messageId}
          reactionsUserIds={message.reactionUserIds}
        />
      )}
    </>
  );
};

export default ChatMessageListItem;
