import React, { forwardRef } from 'react';

import classNames from 'components/styled/util';

interface InputProps {
  type?: 'text';
  value?: string;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e?: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  suffix?: JSX.Element;
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
}

const Input = forwardRef((props: InputProps, ref: React.Ref<HTMLInputElement>) => {
  const {
    onChange,
    onFocus = () => {},
    onKeyUp = () => {},
    onKeyDown = () => {},
    onBlur = () => {},
    value,
    placeholder,
    type = 'text',
    suffix,
    className,
    disabled,
    readOnly
  } = props;

  return (
    <div className="group relative w-full">
      <input
        ref={ref}
        className={classNames(
          'h-11 w-full rounded-2 border border-gray-100 bg-gray-100 px-4 text-14 text-black-body',
          // focus
          'focus:border-primary-100',
          {
            'pr-10 rtl:pr-4 rtl:pl-10': !!suffix
          },
          className
        )}
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
      />

      {suffix && suffix}
    </div>
  );
});
Input.displayName = '123';

export default Input;
